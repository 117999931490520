<template>
  <ValidationProvider :rules="rules" v-slot="v">
              <c-input
                type="text"
                class="ui-text-left "
                :label="label"
                size=""
                :error="v.failed"
                :success="v.passed"
                :state="v.passed?'SUCCESS':v.failed?'ERROR':'DEFAULT'"
                :value="value"
                @input="input"
              >
                <template slot="icon" slot-scope="icon" >
                  <Icon v-if="v.passed" name="check" filled class="ui-transition-all ui-in-out ui-duration-300" :color="icon.icon.iconStyle" />
                  <Icon v-if="v.failed" name="error" filled class="ui-transition-all ui-in-out ui-duration-300" :color="icon.icon.iconStyle" />
                </template>
              </c-input>
              <div class="ui-text-left c-text-b3 c-text-red-400">
                {{ v.errors[0] }}
              </div>
            </ValidationProvider>
</template>

<script>
import { CInput,Icon} from '@estrategiahq/coruja-web-ui'
import { ValidationProvider} from 'vee-validate'
export default {
    components:{
        CInput,
        Icon,
        ValidationProvider,
    },
    props: {
        value: { type: String, default: '' },
        label: { type: String, required: true },
        rules: { type: String, default: ''},
    },
    methods:{
        input(value){
           this.$emit('input',value)
        }
    }

}
</script>

<style>

</style>